/*
 *
 * SPORTOVISTE
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */

var sliders = [];

/****************************************************************************************************************************************************************************************
DOCUMENT READY
****************************************************************************************************************************************************************************************/

jQuery(document).ready(function($) {

/**********************************************
VARIOUS
**********************************************/

	// Scrollování ke kotvě
	$('.to-anchor').scrollToAnchor();

	// Show/hide
	$('.sh-trig').toggler();

	// Scrollspy
	$('.scrollspy').scrollSpy();

	// Tabs
	$('.tabs').liteTabs();

	// Language switcher
	$('.lng > a').on('click', function() {
		var lng = $(this).closest('.lng');

		if (!lng.hasClass('active')) {
			lng.addClass('active');
		} else {
			lng.removeClass('active');
		}
	});

	// Wrap table
	$('table').wrap('<div class="table-wrap"></div>');

	// Stacktable
	$('.entry table').stacktable();

	/**********************************************
  FORM STYLING
  **********************************************/

  var checkbox = $('input[type="checkbox"]');

  checkbox.each(function(index, element) {
    var wrapper = $(element).wrap('<div class="checker"></div>');

    $(element).on('change', function() {
    	wrapper = $(this).closest('.checker');
    	
      if (element.checked) {
        wrapper.addClass('active');
      } else {
        wrapper.removeClass('active');
      }
    });
  });

	/**********************************************
	MODAL BOX
	**********************************************/

  $(function() {
    var trigger = $('.modal-trigger');
    var close = $('.modal-close');
	var autoOpenModal = $('.modal[data-open="true"]');
	var triggerN = $('.modal-open');
	
	autoOpenModal.each(function(index, element) {
		if ($(element).attr('data-cookie')) {
			var cookieName = $(element).attr('data-cookie');
			if (ReadCookie(cookieName) == null) {
				if ($(element).attr('data-session') == 'true') {
					SetSessionCookie(cookieName, true);
				} else {
					SetCookie(cookieName, true, 365);
				}
				$(element).addClass('active');
				$('body').addClass('modal-opened');
			}
		} else {
			$(element).addClass('active');
			$('body').addClass('modal-opened');
		}
	});

	triggerN.each(function(index, element) {
		var id = $(element).attr('data-target');
  
		$(element).on('click', function() {
			$(id).addClass('active');
			$('body').addClass('modal-opened');
		});
	});

    trigger.each(function(index, element) {
      var id = element.hash;
      var modal = $(id);

      $(element).on('click', function() {
        modal.addClass('active');
        $('body').addClass('modal-opened');
      });
    });

    // Close link
    close.on('click', function() {
      $('.modal').removeClass('active');
      $('body').removeClass('modal-opened');
    });

    // Overlay click
    $(window).on('click', function(e) {
      $('.modal, .modal-box-wrap').each(function(index, element) {
        if (e.target === element) {
          $('.modal').removeClass('active');
          $('body').removeClass('modal-opened');
        }
      });
    });

    // Escape close
    $(window).on('keyup', function(e) {
      if (e.keyCode === 27) {
        $('.modal').removeClass('active');
        $('body').removeClass('modal-opened');
      }
    });
  });

	/**********************************************
	AJAX FORM
	**********************************************/

	var ajaxContent = $("#form-ajax-content");
	var siteUrl = window.location.href;

	$('body').delegate('.form-ajax input, .form-ajax select','change', function(e) {
		var parameters = $(this).closest('form').serialize(); // Get form parameters
		var url = window.location.pathname + '?' + parameters;

		window.history.replaceState(null, null, url); // Change url

		if (!url) {
			return;
		}
		url = url + " #form-ajax-content > .inner";
		ajaxContent.addClass('loading').load(url, function() {
			ajaxContent.removeClass('loading');

			$(window).trigger('filterLoad');
		});
	});

	/**********************************************
	COOKIEBAR
	**********************************************/

	/*
	var webCookie = "website"+"Cookie" // přepsat dle domény
	var webCookiePrepend = "body" // prvek kde se má vyobrazit

	var cookieisok = ""+ReadCookie(webCookie);
	var testCookie = "";
	SetCookie("testCookie","ok",365);
	var testCookie = ""+ReadCookie('testCookie'); // test cookies availability

	$(document).ready(function(){
		if(cookieisok != "ok" && testCookie == "ok") {
			$(webCookiePrepend).prepend("<div class='cookie-notice'>Pro účely analýzy návštěvnosti používáme nástroj Google Analytics, který využívá soubory cookies. Předpokládáme, že s použitím cookies souhlasíte. <a href='javascript:;' class='cookie-button'>SOUHLASÍM</a></div>")
		}
		$('.cookie-button').click(function(){
  		$(".cookie-notice").fadeOut(300);
  		SetCookie(webCookie,"ok",365);
		});
	});
	*/

	/**********************************************
	CONTACT FORM 7
	**********************************************/

	// Nascrolluje na chybovou hlášku
	document.addEventListener('wpcf7invalid', function( event ) {
	  $('html:not(:animated),body:not(:animated)').animate({ scrollTop: $('.wpcf7-form').offset().top - 20 - $('.header').height() }, 1000 );
	}, false );

  // Nascrolluje na success hlášku
  document.addEventListener('wpcf7mailsent', function( event ) {
	  $('html:not(:animated),body:not(:animated)').animate({ scrollTop: $('.wpcf7-form').offset().top - 20 - $('.header').height() }, 1000 );

	  // Google
	  //$('form.form-contact').append("<script> gtag('event', 'conversion', {'send_to': 'AW-663704445/qfZoCKu548gBEP2mvbwC'}); </script>");

	  // Sklik
	  //$('form.form-contact').append('<iframe width="119" height="22" frameborder="0" scrolling="no" src="//c.imedia.cz/checkConversion?c=100060627&amp;color=ffffff&amp;v="></iframe>');
	}, false );

	/**********************************************
	FIXER
	**********************************************/

	$(window).on('load scroll', function() {
		if ($('.fixer').length) {
			$('.fixer-start').each(function() {
				var scrollTop = $(window).scrollTop();
				var winH = $(window).height();
				var fixElWrapper = $(this);
				var fixEl = fixElWrapper.find('.fixer');
				var offset = 0;
				if (fixEl.attr('data-offset')) {
					var offset = fixEl.data('offset');
				}

				var fixElWrapperTop = fixElWrapper.offset().top + offset;

				var fixElHeight = fixEl.height();
				var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

				// Top panel
				if (scrollTop > fixElWrapperTop) {
					fixEl.addClass('fixed');

					// Hide in the end
					if (scrollTop > end) {
						fixEl.fadeOut();
					}
					else {
						fixEl.fadeIn();
					}
				}
				else {
					fixEl.removeClass('fixed');
				}
			});
		}
	});

	/**********************************************
	MOBILE NAV
	**********************************************/

	$(function() {
		var body              = $('body');
	  var mobileNav         = $('.mobile-nav');
	  var mobileNavOverlay  = $('.mobile-nav-overlay');

	  function showNav() {
	    body.addClass('mobile-nav-opened');
	  }

	  function hideNav() {
	    body.removeClass('mobile-nav-opened');
	  }

	  // Navigation button
	  $('.nav-btn').on('click', function(e) {
	    if (!body.hasClass('mobile-nav-opened')) {
	      showNav();
	    }
	    else {
	      hideNav();
	    }
	    return false;
	  });

	  // Mobile nav overlay close
	  $('.mobile-nav-overlay').on('click', function(e) {
	    hideNav();
	  });

	  $('.mobile-nav li a').each(function(e) {
	    if ($(this).siblings('ul').length > 0) {
	      $(this).append('<span class="more"></span>');
	    }
	  });

	  // Mobile nav accordion
	  $('.mobile-nav li .more').on('click', function(e) {
	    var link = $(this).closest('a');
	    var ul = link.siblings('ul');

	    if (ul.length > 0) {
	      if (!ul.hasClass('active')) {
	        link.addClass('active');
	        ul.addClass('active');
	      } else {
	        link.removeClass('active');
	        ul.removeClass('active');
	      }
	      return false;
	    }
	  });
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function() {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function() {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function() {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function() {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
	GLIDE
	**********************************************/

	// Photo slider
	if ($('.photo-slider').length > 0) {
	  var photoSlider = new Glide('.photo-slider', {
	    type: 'carousel',
	    startAt: 0,
	    perView: 3,
	    peek: 0,
	    focusAt: 'center',
	    //animationDuration: 0,
	    gap: 30,
	    swipeThreshold: true,
	    dragThreshold: false,
	    breakpoints: {
	      991: {
	      	focusAt: 1,
	        perView: 2
	      },
	      575: {
	      	focusAt: 'center',
	        perView: 1
	      }
	    }
	  }).mount()
	}

	/**********************************************
  SIMPLE LIGHTBOX
  **********************************************/

  // Simple lightbox
	var lightbox = new SimpleLightbox({
	  elements: ".fancybox, a[href$='.jpg'], a[href$='.jpeg'], a[href$='.webp'], a[href$='.png'], a[href$='.gif'], [data-fancybox='gallery'], [data-purpose='play']"
	});

	// Swipe  
	document.addEventListener('swiped-left', function(e) {
	  if ($('html.slbActive').length > 0) {
	    lightbox.next();
	  }
	});

	document.addEventListener('swiped-right', function(e) {
	  if ($('html.slbActive').length > 0) {
	    lightbox.prev();
	  }
	});

});

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Scrollspy
(function($) {
	$.fn.scrollSpy = function (settings) {
		settings = jQuery.extend({
			showSpeed: 100,
			callback: function() {}
		}, settings);

		var topMenu = $(this);

		if (topMenu.length) {
			// Cache selectors
			var lastId,
				topMenuHeight = topMenu.outerHeight(),

				// All list items
				menuItems = topMenu.find("a"),

				// Anchors corresponding to menu items
				scrollItems = menuItems.map(function(){
					var item = $( $(this).attr("href") );
					if (item.length) { return item; }
				});

			// Bind to scroll
			$(window).on('load scroll', function(){
				// Get container scroll position
				var fromTop = $(this).scrollTop() + topMenuHeight + 50;

				// Get id of current scroll item
				var cur = scrollItems.map(function(){
					if ($(this).offset().top < fromTop)
					return this;
				});
				// Get the id of the current element
				cur = cur[cur.length-1];
				var id = cur && cur.length ? cur[0].id : "";

				if (lastId !== id) {
					lastId = id;
					// Set/remove active class
					menuItems
					.parent().removeClass("active")
					.end().filter("[href*=\\#" + id + "]").parent().addClass("active");
				}
			});
		}
	}
})(jQuery);

// Univerzální show/hide
(function($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false
		}, settings);

		$(this).each(function(index,value) {

			var trigger = $(this);
			var content = $('#' + trigger.data('content'));

			// Radio nebo checkbox
			if ( trigger.is(':radio') || trigger.is(':checkbox') ) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function() {
					content.hide();
				});
				trigger.on('change', function() {
					if ( $(this).is(':checked') ) {
						content.fadeIn(settings.showSpeed);
					}
					else {
						content.hide();
					}
				});
				$(window).on('load',function() {
					if ( trigger.is(':checked') ) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				function scrollToContent() {
					var offset = 0;
					if ( settings.offsetScroll ) {
						offset = settings.offsetScroll
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $(content).offset().top + offset }, 500);
				}

				function scrollToTrigger() {
					var offset = 0;
					if ( settings.offsetScroll ) {
						offset = settings.offsetScroll + 200
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset }, 500);
				}

				trigger.on('click',function(event) {
					if ( content.is(':hidden') ) {
						if ( settings.slide ) {
							content.slideDown(settings.showSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToContent();
								};
							});
						}
						else {
							content.fadeIn(settings.showSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToContent();
								};
							});
						}

						$(this).addClass('active').find('.text').text( $(this).attr('data-less') );

						if ( settings.hideTrigger ) {
							trigger.hide();
						}
					}
					else {
						if ( settings.slide ) {
							content.slideUp(settings.hideSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToTrigger();
								};
							});
						}
						else {
							content.fadeOut(settings.hideSpeed,function() {
								if ( settings.scrollToContent ) {
									scrollToTrigger();
								};
							});
						}
						$(this).removeClass('active').find('.text').text( $(this).attr('data-more') );

						if ( settings.hideTrigger ) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					event.preventDefault();
					//return false;
				});
			}
			// Hide on document click
			if ( settings.hideOnDocumentClick || trigger.attr('data-hideonclick') == 'true' ) {
				$(document).on('click', function(e) {
					if ( (! $(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) && ( ! trigger.is(e.target) && trigger.has(e.target).length === 0 ) )  {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
			// Escape button
			if ( settings.hideOnEscape ) {
				$(document).keyup(function(e){
					if (e.keyCode === 27) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
		});
	}
})(jQuery);

// Plynulý scroll ke kotvě
(function($) {
	$.fn.scrollToAnchor = function(settings) {

		settings = jQuery.extend({
			speed : 750
		}, settings);

		return this.each(function(){
			var caller = this
			$(caller).click(function (event) {
				event.preventDefault()
				var locationHref = window.location.href
				var elementClick = $(caller).attr("href")

				var destination = $(elementClick).offset().top;

				$("html:not(:animated),body:not(:animated)").animate({
						scrollTop: destination
					}, {
					  	duration: settings.speed,
					  	//easing: "easeInOutQuint",
					  	complete: function() {
							history.pushState(null, null, elementClick);
						}
					}
				);
				return false;
			});
		});
	}
})(jQuery);

// Lite tabs
(function($) {
	$.fn.liteTabs = function(options) {
    return this.each(function() {
  		var defaults = {
  			fadeIn 			: false,
  			height 			: 'auto',
  			hideHash 		: false,
  			hashTag 		: true,
  			selectedTab : 1,
  			width 			: 500
  		};

  		// Merge defaults with options in new settings object
  		var settings = $.extend({}, defaults, options);

  		// Define key variables
  		var $this = $(this);
  		var $ul = $this.find('.tab-nav ul');
  		var $tab = $ul.find('a');
  		var $div = $this.find('.tab-content').first().find('.tab');

			// Tab click
			$tab.click(function(e) {
				var hash = this.hash;
				var id = hash.replace('#','');
				var selectedPanel = $div.filter('[id="' + id + '"]');
				var filterHash = $div.removeClass('selected').filter('[id="' + id + '"]');

				// defaults: add selected class to tab
				$tab.removeClass('selected').filter(this).addClass('selected');

				// Rodičovská záložka zůstane otevřená při kliku na vnořené záložky v záložce
				var parentId = $tab.filter(this).closest('.tab').attr('id');

				$('.tab[id="' + parentId + '"]').addClass('selected');
				$('.tab-nav a[href="' + parentId + '"]').addClass('selected');

				if ($tab.parents('.tabs').length > 1 ) {
					e.preventDefault();
				}

				// Show panel
				filterHash.addClass('selected');

				// Option: hide hash change
				if (settings.hideHash) {
					e.preventDefault();
				}

				return false;
			});

			// Option: set selected tab
			if (settings.selectedTab) {
			 	$tab.eq(settings.selectedTab - 1).addClass('selected');
			 	$div.eq(settings.selectedTab - 1).addClass('selected');
			}

			// If hash tag..
			var hash = window.location.hash.substring(1);
			$('[href="#' + hash + '"]').click();
    });
	};
})(jQuery);

// Cookies
function ReadCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function SetCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function SetSessionCookie(name, value) {
  document.cookie = name + "=" + value + ";path=/";
}

function DeleteCookie(name) {
  setCookie(name, '', -1);
}